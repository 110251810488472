<template>
  <div class="cookie-banner">
    <div v-if="isCookieBannerShown || isCookieBannerShown === null" class="banner dark uk-alert col-all-12" uk-alert>
      <a class="uk-alert-close" uk-close @click="hideCookieBanner"></a>
      <i class="fas fa-cookie"></i>
      <div class="text col-mdmobile-10 col-smobile-10">
        <p class="primary">{{ $t('cookie-banner_component.enhance-user-experience') }}</p>
        <p class="primary">
          {{ $t('cookie-banner_component.overview-privacy-policy') }}
          <a href="//leafcad.com/terms#privacy-policy">{{ $t('cookie-banner_component.privacy-policy') }}</a>
        </p>
      </div>
      <button class="primary-full col-mdmobile-10 col-smobile-10" @click="hideCookieBanner">
        {{ $t('actions.accept') }}
      </button>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/components/cookie-banner.less"></style>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CookieBanner',
  computed: {
    ...mapState('cookieBanner', ['isCookieBannerShown'])
  },
  methods: {
    hideCookieBanner: function() {
      this.$store.dispatch('cookieBanner/setCookieBannerVisible', false)
    }
  }
}
</script>
